export default function InformationBlock({ headline, subText, Icon, hideButton, buttonLink, buttonText }) {
	return (
		<div className="absolute inset-0 bg-dania-600 pt-10">
			<div className="m-5 p-5 pb-10 rounded-md bg-white">
				<div className="text-center m-3">
					<div className="inline-flex">
						<Icon className="inline-block text-dania-500 h-12 w-12"></Icon>
					</div>
					<p className="font-medium mb-2">{headline} </p>
					<p className="font-small mb-2">{subText}</p>
					{hideButton ? null : (
						<div className="pt-10">
							<a class="bg-dania-500 hover:bg-dania-700 text-white font-bold py-2 px-4 rounded" target="_blank" href={buttonLink ? buttonLink : "mailto:support@daniasoftware.com"}>
								{buttonText ? buttonText : "Contact us"}
							</a>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
