import { useEffect, useState } from "react";
import { GetProfile, UpdateDefaultSignature, UpdateFields } from "./ApiClient/ProfileClient";
import SignatureClient from "./ApiClient/SignatureClient";
import TextBox from "./Components/TextBox";
import CustomButton from "./Components/CustomButton";
import { UpdateOutlookSignature, GetUserToken, GetIsInternal, CustomProp } from "./OfficeAddIn";
import { InformationCircleIcon, CheckCircleIcon, ExclamationCircleIcon, ChevronUpIcon, ChevronDownIcon, UserIcon } from "@heroicons/react/solid";
import { ClockIcon, ExclamationCircleIcon as ExclamationCircleIconOutline, InformationCircleIcon as InformationCircleIconOutLine } from "@heroicons/react/outline";
import useStateWithCallback from "use-state-with-callback";
import { GetStatus } from "./ApiClient/License";
import InformationBlock from "./Components/InformationBlock";
import Spinner from "./Components/Spinner";
/*global Office*/

export default function App() {
	const [fields, setFields] = useStateWithCallback([], () => {
		setShowFields(checkForAnyMissingValues());
	});
	const [showFields, setShowFields] = useState(false);
	const [overrideShowFields, setOverrideShowFields] = useState({ show: false, active: false });
	const [signatures, setSignatures] = useState([]);
	const [selectedSignature, setSelectedSignature] = useState({});
	const [signatureType, setSignatureType] = useState(null);
	const [userProfile, setUserProfile] = useState({});
	const [token, setToken] = useState();
	const [busy, setBusy] = useState(false);
	const [isInternal, setIsInternal] = useState(false);
	const [notificationBar, setNotificationBar] = useState({ show: false, text: "", icon: CheckCircleIcon, color: "green" });
	const [noSignatures, setNoSignatures] = useState(false);
	const [licenseStatus, setLicenseStatus] = useState();

	useEffect(() => {
		Office.onReady(() => {
			Office.context.mailbox.item.notificationMessages.removeAsync('ActionBarMessage');
			GetIsInternal().then((isInternalResponse) => {
				Office.context.mailbox.item.addHandlerAsync(Office.EventType.RecipientsChanged, () => {RecipientChange(isInternalResponse);}, (asyncResult) => {});
				setIsInternal(isInternalResponse);
				GetUserToken().then((x) => {
					setToken(x);
				});
			});
		});
	}, []);

	useEffect(() => {
		if (token && !licenseStatus) {
			GetStatus(token).then((status) => {
				setLicenseStatus(status);
			});
		}
	}, [token]);

	useEffect(() => {
		if (token && licenseStatus.valid) {
			Office.onReady(() => {
				Office.context.mailbox.item.getComposeTypeAsync(function (asyncResult) {
					if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
						var mailtype = asyncResult.value.composeType;

						SignatureClient(mailtype, isInternal, token).then((x) => {
							if (x.totalResults > 0) {
								let localSignatures = x.results;
								let signatureType = x.resolvedType;
								setSignatureType(signatureType);
								setSignatures(localSignatures.slice().sort((a, b) => a.name.localeCompare(b.name)));
								let signature = JSON.parse(JSON.stringify(localSignatures[0]));
								setNoSignatures(false);
								GetProfile(token).then(async (data) => {
									if (data.fields == null) {
										data.fields = [];
									}
									setUserProfile(data);

									let defaultSignature = await CustomProp("DaniaLoadedSignatureId");
									if (defaultSignature) {
										let defaultSignatureList = localSignatures.filter((x) => x.signatureId === defaultSignature);
										if (defaultSignatureList.length > 0) {
											signature = JSON.parse(JSON.stringify(defaultSignatureList[0]));
										}
									}

									setSelectedSignature(signature);

									// Stringify to variable before parsing, to prevent lost field values.
									let signatureFieldsJson = JSON.stringify(signature.fields);
									let localFields = JSON.parse(signatureFieldsJson);

									localFields.forEach((f) => {
										let signatureFields = data.fields[signature.signatureId];
										if(signatureFields != null){
										var field = signatureFields.filter((userFields) => userFields.key === f.name);
										if (field.length > 0 && field[0].value !== "") {
											f.value = field[0].value;
										} else if (f.id < 1000000) {
											// Non-SoMeLink field-values are cleared if no match is found in userFields.
											f.value = "";
										}
									}
									});
									setFields(localFields);
								});
							} else {
								setNoSignatures(true);
								setShowFields(true);
							}
						});
					}
				});
			});
		}
	}, [licenseStatus]);

	function RecipientChange(internalStatus) {
		GetIsInternal().then((isInternalResponse) => {
			if(isInternalResponse != internalStatus)
			Office.context.ui.closeContainer();
		});
	}

	function toggleOverrideShowFields() {
		setOverrideShowFields({ show: !overrideShowFields.show, active: true })
	}

	function showProfileFields() {
		if (overrideShowFields.active)
			return overrideShowFields.show;
		else
			return showFields;
	}

	function selectSignature(e) {
		setBusy(true);
		let signature = signatures.filter((x) => x.signatureId === e.target.value)[0];
		setSelectedSignature(signature);
		signature = JSON.parse(JSON.stringify(signature));
		setFieldValues([...signature.fields], signature.signatureId);
		UpdateOutlookSignature(signature.signatureId, token).then((data) => {
			setBusy(false);
		});
	}

	function isSelectedType(id) {
		return signatureType === id;
	}

	function setFieldValues(localFields, signatureId) {
		localFields = [...localFields];
		localFields.forEach((f) => {
			var userProfileFields = userProfile.fields[signatureId] ? userProfile.fields[signatureId] : [];

			var field = userProfileFields.filter((userFields) => userFields.key === f.name);
			if (field.length > 0) {
				f.value = field[0].value;
			} else {
				f.value = "";
			}
		});

		setFields(localFields);
	}

	function changeDefaultSignature() {
		UpdateDefaultSignature(selectedSignature.signatureId, signatureType, token).then((x) => {
			if (x.status === 200) notficationUpdate("Success! Your default signature has been saved.", CheckCircleIcon, "green");
			else notficationUpdate("Something went wrong, please try again.", InformationCircleIcon, "red");
		});
	}

	function saveFieldData() {
		setBusy(true);

		var cloneDictionarie = {...userProfile.fields};
		var cloneFields = !Object.keys(cloneDictionarie).length || !cloneDictionarie[selectedSignature.signatureId] ? [] : [...cloneDictionarie[selectedSignature.signatureId]];

		fields.forEach((x) => {
			let cfs = cloneFields.filter((cf) => cf.key === x.name);
			if (cfs.length > 0) {
				let fieldCompare = selectedSignature.fields.filter((field) => field.name === x.name)[0];
				if (fieldCompare.azureADOverwrite === x.value) {
					cfs[0].value = "";
				} else {
					cfs[0].value = x.value;
				}
			} else {
				let fieldCompare = selectedSignature.fields.filter((field) => field.name === x.name)[0];
				if (fieldCompare.azureADOverwrite === x.value) {
					cloneFields.push({ key: x.name, value: "" });
				} else {
					cloneFields.push({ key: x.name, value: x.value });
				}
			}
		});
		
		UpdateFields(selectedSignature.signatureId, cloneFields, token).then((x) => {
			if (x.status === 200) notficationUpdate("Success! Your details have been saved and are now used by default.", CheckCircleIcon, "green");
			else notficationUpdate("Something went wrong, please try again.", InformationCircleIcon, "red");
			cloneDictionarie[selectedSignature.signatureId] = cloneFields;
			setUserProfile((prevState) => ({ ...prevState, fields: cloneDictionarie }));
			UpdateOutlookSignature(selectedSignature.signatureId, token).then((data) => {
				setBusy(false);
				setOverrideShowFields({ show: false, active: false });
			});
		});
	}

	function changeFieldValue(id, value) {
		let cloneFields = [...fields];
		cloneFields.filter((x) => x.id === id)[0].value = value.target.value;
		cloneFields.filter((x) => x.id === id)[0].hasChanged = true;
		setFields(cloneFields);
	}

	function recreateSignature() {
		setBusy(true);
		UpdateOutlookSignature(selectedSignature.signatureId, token).then((data) => {
			setBusy(false);
		});
	}

	function notficationUpdate(text, icon, color) {
		setNotificationBar({ text: text, show: true, icon: icon, color: color });
		new Promise((x) => setTimeout(x, 5000)).then(() => setNotificationBar((prevState) => ({ ...prevState, show: false })));
	}

	function checkForMissingValue(name) {
		var selectedField = selectedSignature.fields.filter((x) => x.name === name && x.allowOverwrite);
		if (selectedField.length > 0 && selectedField[0].value === "") {

			var userProfileFields = userProfile.fields[selectedSignature.signatureId] ? userProfile.fields[selectedSignature.signatureId] : [];

			var field = userProfileFields.filter((x) => x.key === name);
			if (!field || field.length === 0 || field[0].value === "") {
				return true;
			}
		}
		return false;
	}

	function checkForAnyMissingValues() {
		var missingValue = false;
		fields.forEach((x) => {
			if (checkForMissingValue(x.name)) {
				missingValue = true;
			}
		});
		return missingValue;
	}

	const deactivatedLicense = () => {
		return {
			headline: "Your SignatureCloud is deactivated.",
			subText: "Contact us to reactivate",
			Icon: ExclamationCircleIconOutline,
		};
	};

	const expiredLicense = () => {
		return {
			headline: "Your SignatureCloud license has expired.",
			subText: "Contact us to upgrade to a full license",
			Icon: ClockIcon,
		};
	};

	const noActiveSignature = () => {
		return {
			headline: "You have no available signatures.",
			subText: "Contact an administrator or create signatures by opening the link below.",
			Icon: InformationCircleIconOutLine,
			buttonText: "Create signature",
			buttonLink: "https://apps.daniasoftware.com/products/SignatureCloud",
		};
	};

	return (
		<div>
			{signatures.length === 0 ? (
				licenseStatus && licenseStatus.valid === false ? (
					InformationBlock(licenseStatus.expired ? expiredLicense() : deactivatedLicense())
				) : noSignatures ? (
					InformationBlock(noActiveSignature())
				) : (
					<Spinner />
				)
			) : (
				<div className="w-full flex flex-col p-3">
					{notificationBar.show ? (
						<div className={"rounded-md bg-" + notificationBar.color + "-50 mb-6 p-4 w-full"}>
							<div className="flex">
								<div className="flex-shrink-0">
									<notificationBar.icon className={"h-5 w-5 text-" + notificationBar.color + "-400"} aria-hidden="true" />
								</div>
								<div className="ml-3 flex-1 md:flex md:justify-between">
									<p className={"text-sm font-medium text-" + notificationBar.color + "-700"}>{notificationBar.text}</p>
								</div>
							</div>
						</div>
					) : null}
					{checkForAnyMissingValues() ? (
						<div className={"rounded-md bg-yellow-50 mb-6 p-4 w-full"}>
							<div className="flex">
								<div className="flex-shrink-0">
									<ExclamationCircleIcon className={"h-5 w-5 text-yellow-400"} aria-hidden="true" />
								</div>
								<div className="ml-3 flex-1 md:flex md:justify-between">
									<p className={"text-sm text-yellow-800"}>
										<span className="font-medium">Attention:</span> Information is missing in your signature. Fill out the details below.{" "}
									</p>
								</div>
							</div>
						</div>
					) : null}
					{noSignatures ? (
						<p>No signatures found</p>
					) : (
						<div>
							<p className="font-medium mb-2">Change signature</p>
							<div className="flex gap-6 mb-2">
								<div className="form-check form-check-inline">
									<input
										className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-gray-400 checked:border-gray-400 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 text-gray-400"
										type="radio"
										name="inlineRadioOptions"
										id="inlineRadio1"
										value="newMail"
										disabled
										checked={isSelectedType(0)}
									/>
									<label className="form-check-label inline-block text-gray-300" for="inlineRadio10">
										Standard
									</label>
								</div>
								<div className="form-check form-check-inline">
									<input
										className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-gray-400 checked:border-gray-400 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 text-gray-400"
										type="radio"
										name="inlineRadioOptions"
										id="inlineRadio2"
										value="reply"
										disabled
										checked={isSelectedType(1)}
									/>
									<label className="form-check-label inline-block text-gray-300" for="inlineRadio20">
										Replies
									</label>
								</div>
								<div className="form-check form-check-inline">
									<input
										className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-gray-400 checked:border-gray-400 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 text-gray-400"
										type="radio"
										name="inlineRadioOptions"
										id="inlineRadio2"
										value="internal"
										disabled
										checked={isSelectedType(2)}
									/>
									<label className="form-check-label inline-block text-gray-300" for="inlineRadio20">
										Internal
									</label>
								</div>
							</div>
							<div className={"flex gap-2" + (busy ? " opacity-40" : "")}>
								<div className="flex-1">
									<div className="inline-block relative w-full">
										<select
											className={
												"block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 focus:border-dania-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" +
												(busy ? " cursor-not-allowed" : "")
											}
											onChange={selectSignature}
											value={selectedSignature.signatureId}
											defaultValue={selectedSignature.signatureId}
											disabled={busy ? true : null}
										>
											{signatures.map((x) => {
												return (
													<option key={x.signatureId} value={x.signatureId}>
														{x.name}
													</option>
												);
											})}
										</select>
									</div>
								</div>
								<CustomButton
									text="Set as default"
									bgcolor="dania-500"
									textcolor="white"
									className="text-sm py-1 px-2 font-medium flex-1"
									action={() => {
										changeDefaultSignature();
									}}
								/>
							</div>
							<hr className="my-3" />
							<div onClick={() => toggleOverrideShowFields()} class="cursor-pointer flex justify-between">
								<p className="font-medium mb-2 text-left">Profile data</p>
								{showProfileFields() ? <ChevronUpIcon className={"h-7 w-7 float-right"} aria-hidden="true" /> : <ChevronDownIcon className={"h-7 w-7 text-right"} aria-hidden="true" />}
							</div>
							{showProfileFields() ? (
								<div>
									{fields
										.filter((x) => x.allowOverwrite)
										.map((x) => {
											
											let signatureField = selectedSignature.fields.filter((field) => field.name === x.name)[0];
											let azureAD = (signatureField.azureADOverwrite !== null && x.hasChanged === undefined) || signatureField.azureADOverwrite === x.value;

											if (azureAD && x.value !== signatureField.azureADOverwrite && x.value === "") x.value = signatureField.azureADOverwrite;
											let valueIsMission = checkForMissingValue(x.name);
											return (
												<div className="mb-2" key={x.id}>
													<TextBox
														placeholder={x.name}
														fieldName={x.name}
														defaultValue={x.value}
														onChange={(value) => changeFieldValue(x.id, value)}
														iconRight={valueIsMission ? ExclamationCircleIcon : signatureField.azureADOverwrite === x.value ? UserIcon : null}
														iconColor={valueIsMission ? "yellow" : "blue"}
														placeholderColor={valueIsMission ? "yellow" : null}
														borderColor={valueIsMission ? "yellow" : null}
													/>
												</div>
											);
										})}
								</div>
							) : null}
							<CustomButton
								text="Apply"
								bgcolor="dania-500"
								textcolor="white"
								className="text-sm py-3 font-medium w-full"
								action={() => {
									saveFieldData();
								}}
								disabled={busy}
							/>
							<div className="h-10"></div>
							<hr className="my-3" />
							<div className="flex gap-6">
								<CustomButton
									text="Recreate"
									bgcolor="dania-100"
									textcolor="dania-500"
									className="flex-auto text-sm py-1 font-medium"
									action={() => {
										recreateSignature();
									}}
									disabled={busy}
								/>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
}
