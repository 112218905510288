export function GetProfile(token) {
	return fetch("api/getProfile/", {
		headers: {
			"osc-authorization": `Bearer ${token}`,
		},
	}).then((x) => x.json());
}

export function UpdateDefaultSignature(signatureId, signatureType, token) {
	return fetch("api/updateDefaultSignature/", {
		method: "PUT",
		body: JSON.stringify({ defaultSignatureId: signatureId, signatureType: signatureType }),
		headers: {
			"osc-authorization": `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	});
}

export function UpdateFields(signatureId,fields, token) {
	return fetch("api/UpdateFields?signatureId="+signatureId, {
		method: "PUT",
		body: JSON.stringify(fields),
		headers: {
			"osc-authorization": `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	});
}
