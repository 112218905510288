/*global UpdateSignature*/
/*global GetToken*/
/*global IsInternal*/
/*global getCustomProp*/

export async function UpdateOutlookSignature(signatureId, licenseId, token) {
	return UpdateSignature(signatureId, licenseId, token);
}

export async function GetUserToken() {
	return GetToken();
}

export async function GetIsInternal() {
	return IsInternal();
}

export async function CustomProp(value) {
	return getCustomProp(value);
}
