export default function TextBox(props) {
	const GetStyle = () => {
		let borderColor = props.borderColor === null ? "gray" : props.borderColor;
		let placeholderColor = props.placeholderColor === null ? "gray" : props.placeholderColor;

		return props.readOnly
			? "mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-" + borderColor + "-300 placeholder-" + placeholderColor + "-500 rounded-md bg-gray-200"
			: "mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-" + borderColor + "-300 placeholder-" + placeholderColor + "-500 rounded-md";
	};

	return props.iconRight || props.iconLeft ? (
		<div className="relative text-gray-600 focus-within:text-gray-400">
			{props.iconLeft ? (
				<span className="absolute inset-y-0 left-0 flex items-center pl-2">
					<props.iconLeft className={"h-5 w-5 text-" + props.iconColor + "-500"} />
				</span>
			) : null}
			<input
				readOnly={props.readOnly}
				required
				type={props.type ? props.type : "text"}
				name={props.fieldName}
				id={props.fieldName}
				autoComplete={props.autoComplete}
				className={GetStyle() + ` ${props.iconLeft ? "pl-10" : "pr-10"} `}
				placeholder={props.placeholder}
				// defaultValue={props.defaultValue ? props.defaultValue : ""}
				value={props.defaultValue ? props.defaultValue : ""}
				onChange={(e) => {
					if (props.onChange) {
						props.onChange(e);
					}
				}}
			/>
			{props.iconRight ? (
				<span className="absolute inset-y-0 right-0 flex items-center pr-2">
					<props.iconRight className={"h-5 w-5 text-" + props.iconColor + "-500"} />
				</span>
			) : null}
		</div>
	) : (
		<div className={props.mb != null ? "mb-" + props.mb : ""}>
			{!props.multiLine ? (
				<input
					readOnly={props.readOnly}
					required
					type={props.type ? props.type : "text"}
					name={props.fieldName}
					id={props.fieldName}
					autoComplete={props.autoComplete}
					className={GetStyle()}
					placeholder={props.placeholder}
					defaultValue={props.defaultValue ? props.defaultValue : ""}
					value={props.defaultValue ? props.defaultValue : ""}
					onChange={(e) => {
						if (props.onChange) {
							props.onChange(e);
						}
					}}
				/>
			) : (
				<textarea
					readOnly={props.readOnly}
					required
					type={props.type ? props.type : "text"}
					name={props.fieldName}
					id={props.fieldName}
					autoComplete={props.autoComplete}
					className={GetStyle()}
					placeholder={props.placeholder}
					defaultValue={props.defaultValue ? props.defaultValue : ""}
					value={props.defaultValue ? props.defaultValue : ""}
					onChange={(e) => {
						if (props.onChange) {
							props.onChange(e);
						}
					}}
					rows="3"
				></textarea>
			)}
		</div>
	);
}
