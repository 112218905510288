export default function CustomButton({text, action, bgcolor, textcolor, className, disabled}) {

    function clickEvent(){
        if(disabled){

        }else{
            action();
        }
    }
    
    return <button className={"bg-"+bgcolor+" hover:bg-"+bgcolor+" text-"+textcolor+" rounded" + (className ? " " + className : "") + (disabled ? " cursor-not-allowed opacity-40" : "")} onClick={() => {clickEvent()}}>{text}</button>;
}